@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,500;1,400&family=Rowdies&display=swap&family=Inconsolata");
@import url("shared-components/vars.css");

* {
  margin: 0;
  font-family: "Jost", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

code {
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
}

body {
  /* width: 100%;
  margin: 0;
  padding: 0;
  height: 100%; */
  overflow-x: hidden;
}

p,
span {
  cursor: default;
}

a {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: inherit;
}

.Link-highlight {
  text-decoration: none;
  color: #36363c;
  padding: 0 2px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  box-shadow: inset 0 -2px 0 var(--color-dark-blue);
}

.Link-highlight:hover {
  box-shadow: inset 0 -55px 0 0 var(--color-dark-blue);
  color: white;
}
