@import url("../../shared-components/vars.css");

.Writing-page-content {
  display: flex;
}

.Writing-archive {
  display: block;
  align-items: center;
  padding-top: 20px;
}

.Writing-line {
  padding-top: 0px;
  padding-bottom: 13px;
  font-size: 14px;
}

.Writing-line--title {
  font-weight: 500;
  text-decoration: none;
}

.Writing-line--title.forthcoming {
  color: var(--color-dark-periwinkle);
}

.Writing-line-container {
  padding-right: 50px;
}

.Writing-line--forthcoming {
  font-weight: 500;
  text-decoration: none;
}

.Writing-line--published_by {
  color: var(--color-dark-periwinkle);
  margin: 0 2px;
}

.Writing-line--published_on {
  color: var(--color-dark-periwinkle);
}

.Writing-line--award {
  color: var(--color-dark-periwinkle);
  padding-top: 10px;
}

.Writing-page-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Writing-thought-container {
  position: absolute;
  left: 250px;
}

.Writing-thought-header {
  font-size: 28px;
  font-family: "Rowdies", cursive;
  margin-bottom: 10px;
}

.column.Writing-sub-header {
  font-weight: 500;
  width: 150px;
}

.column.Writing-story-col {
  width: 500px;
}

.row {
  content: "";
  display: table;
  clear: both;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  padding: 10px;
}
