@import "../vars.css";

.Header-text {
  font-size: var(--font-size-welcome);
  font-family: var(--font-header);
  letter-spacing: 1.4px;
}

.Header-container {
  text-align: center;
  padding: 50px 0 10px;
}
