.DesignNotes-section {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.About-container {
  padding: 30px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.75px;
}

.Girl-dozing {
  height: 50vh;
}

.Girl-dozing-container {
  display: block;
  margin-left: 100px;
}

.About-dropdown {
  z-index: 2;
  width: 22rem;
  text-align: justify;
}

.About-content {
  margin-bottom: 20px;
}

.About-text {
  line-height: 1.5;
  font-size: 14px;
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .About-container {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0;
    margin-left: 50px;
  }

  .About-dropdown {
    margin: 0;
    text-align: left;
  }

  .Girl-dozing-container {
    margin-left: 50px;
  }

  .DesignNotes-section {
    padding: 0;
  }
}
