@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,500;1,400&family=Rowdies&display=swap&family=Inconsolata);
:root {
  --color-light-blue: rgb(16, 162, 245);
  --color-dark-blue: #007bff;
  --color-light-periwinkle: #88ace0;
  --color-dark-periwinkle: #7786b7;

  --font-header: "Rowdies", cursive;
  --font-robot: Inconsolata, monospace;

  --font-size-welcome: 25px;
  --font-size-header: 27px;
  --font-size-language-elem: 0.6rem;
  --font-size-project-description: 13px;

  --font-weight-language-elem: 600;

  --project-box-shadow: 0 10px 25px 2px grey;
}

* {
  margin: 0;
  font-family: "Jost", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

code {
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
}

body {
  /* width: 100%;
  margin: 0;
  padding: 0;
  height: 100%; */
  overflow-x: hidden;
}

p,
span {
  cursor: default;
}

a {
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: inherit;
}

.Link-highlight {
  text-decoration: none;
  color: #36363c;
  padding: 0 2px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  box-shadow: inset 0 -2px 0 var(--color-dark-blue);
}

.Link-highlight:hover {
  box-shadow: inset 0 -55px 0 0 var(--color-dark-blue);
  color: white;
}

.Welcome-section {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Welcome-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.Welcome-subtext {
  color: var(--color-dark-periwinkle);
}

.Girl-diva {
  height: 600px;
  margin-top: 50px;
  width: auto;
}

.DisplayText {
  font-size: var(--font-size-welcome);
  font-family: var(--font-header);
}

.Welcome-text-container {
  margin-left: 25px;
  width: 360px;
  min-width: 400px;
  pointer-events: none;
  letter-spacing: 2px;
  line-height: 2;
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .Welcome-container {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 50px 30px 0 0;
  }

  .Girl-diva {
    height: 500px;
    width: auto;
    margin: 30px 0 0 30px;
  }

  .Welcome-text-container {
    margin-left: 40px;
    width: 300px;
    min-width: 300px;
    letter-spacing: 1.25px;
  }

  .DisplayText,
  .Welcome-subtext,
  .Girl-diva {
    margin-top: 30px;
  }

  .Welcome-subtext {
    font-size: 14px;
  }
}

.Footer {
  line-height: 1;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: auto;
  min-height: 70px;
  padding: 15px;
}

a.Footer-credit:hover {
  color: var(--color-dark-blue);
}

a.Footer-credit {
  color: var(--color-dark-blue);
}

.Footer-credit {
  font-weight: 600;
  letter-spacing: 0.025em;
  font-family: Inconsolata, monospace;
  padding: 10px;
  text-decoration: none;
}
.Footer-credit:visited {
  color: rgb(168, 178, 209);
}

.Header-text {
  font-size: var(--font-size-welcome);
  font-family: var(--font-header);
  letter-spacing: 1.4px;
}

.Header-container {
  text-align: center;
  padding: 50px 0 10px;
}

.Experience-section {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Tab-container {
  padding: 20px 0;
  display: block;
  align-items: center;
}

.Tab-container ul {
  display: block;
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  z-index: 3;
  padding: 0px;
  margin-bottom: 20px;
  list-style: none;
}

.Tab-container li {
  display: inline-flex;
}

.Tab-button {
  position: relative;
  margin: 0;
  height: 50px;
  width: 200px;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  padding: 0px 0 2px;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  font-family: var(--font-header);
  text-align: left;
  text-decoration: none;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  background-color: transparent;
  white-space: nowrap;
  border: none;
  border-bottom: 2px solid #cfdef3;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.Tab-button:focus {
  outline: none;
}

.Tab-button:hover {
  background-color: #cfdef3;
}

.Tab-button:active {
  outline: none;
  background-color: #cfdef3;
}

.Tab-heading {
  font-size: 16px;
  font-family: var(--font-header);
}

.Place-location {
  padding: 0;
}

.Place-role {
  font-size: 14px;
}

.Place-location-text {
  font-size: 14px;
  color: var(--color-light-periwinkle);
  font-weight: 500;
}

.Place-location-link {
  padding: 0 3px;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
}

.Place-location-link:hover {
  box-shadow: inset 0 -55px 0 0 var(--color-light-periwinkle);
  color: white;
}

.Place-time {
  font-size: 12px;
  color: var(--color-dark-periwinkle);
}

.Icon-container {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 100px 0 50px 0;
}

.Icon-container li {
  display: inline-flex;
  padding-right: 40px;
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .Tab-button {
    width: 135px;
  }

  .Tab-container {
    padding-left: 50px;
  }
}


.Writing-page-content {
  display: flex;
}

.Writing-archive {
  display: block;
  align-items: center;
  padding-top: 20px;
}

.Writing-line {
  padding-top: 0px;
  padding-bottom: 13px;
  font-size: 14px;
}

.Writing-line--title {
  font-weight: 500;
  text-decoration: none;
}

.Writing-line--title.forthcoming {
  color: var(--color-dark-periwinkle);
}

.Writing-line-container {
  padding-right: 50px;
}

.Writing-line--forthcoming {
  font-weight: 500;
  text-decoration: none;
}

.Writing-line--published_by {
  color: var(--color-dark-periwinkle);
  margin: 0 2px;
}

.Writing-line--published_on {
  color: var(--color-dark-periwinkle);
}

.Writing-line--award {
  color: var(--color-dark-periwinkle);
  padding-top: 10px;
}

.Writing-page-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Writing-thought-container {
  position: absolute;
  left: 250px;
}

.Writing-thought-header {
  font-size: 28px;
  font-family: "Rowdies", cursive;
  margin-bottom: 10px;
}

.column.Writing-sub-header {
  font-weight: 500;
  width: 150px;
}

.column.Writing-story-col {
  width: 500px;
}

.row {
  content: "";
  display: table;
  clear: both;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  padding: 10px;
}

.Dropdown-summary {
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: 500;
  font-size: 16px;
  font-family: "Rowdies", cursive;

  display: flex;
  align-items: center;
}

.Dropdown {
  height: 150px;
  min-height: 200px;
  width: 100%;
  max-width: 300px;
}

.Dropdown p {
  font-size: 14px;
  line-height: 1.5;
}

.Dropdown a {
  color: inherit;
}

.Dropdown-content {
  margin-top: 1.2rem;
}

.Dropdown-point-icon {
  margin-left: 0.5em;
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .Dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
}

.DesignNotes-section {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.About-container {
  padding: 30px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.75px;
}

.Girl-dozing {
  height: 50vh;
}

.Girl-dozing-container {
  display: block;
  margin-left: 100px;
}

.About-dropdown {
  z-index: 2;
  width: 22rem;
  text-align: justify;
}

.About-content {
  margin-bottom: 20px;
}

.About-text {
  line-height: 1.5;
  font-size: 14px;
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .About-container {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0;
    margin-left: 50px;
  }

  .About-dropdown {
    margin: 0;
    text-align: left;
  }

  .Girl-dozing-container {
    margin-left: 50px;
  }

  .DesignNotes-section {
    padding: 0;
  }
}

.Top-button {
  display: inline-block;
  transition: all 0.2s ease-in-out;
  background: 0 0;
  border: none;
  outline: 0;
  width: 45px;
  position: fixed;
  bottom: 25px;
  right: 30px;
  cursor: pointer;
  z-index: 3;
}

.Top-button img {
  width: 100%;
}

@media screen and (max-width: 601px) {
  .Top-button {
    bottom: 15px;
    right: 3px;
    width: 40px;
  }

  .Top-button img {
    width: 40px;
  }
}


