.Top-button {
  display: inline-block;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: 0 0;
  border: none;
  outline: 0;
  width: 45px;
  position: fixed;
  bottom: 25px;
  right: 30px;
  cursor: pointer;
  z-index: 3;
}

.Top-button img {
  width: 100%;
}

@media screen and (max-width: 601px) {
  .Top-button {
    bottom: 15px;
    right: 3px;
    width: 40px;
  }

  .Top-button img {
    width: 40px;
  }
}
