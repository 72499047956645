@import "../../shared-components/vars.css";

.Experience-section {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Tab-container {
  padding: 20px 0;
  display: block;
  align-items: center;
}

.Tab-container ul {
  display: block;
  position: relative;
  width: max-content;
  z-index: 3;
  padding: 0px;
  margin-bottom: 20px;
  list-style: none;
}

.Tab-container li {
  display: inline-flex;
}

.Tab-button {
  position: relative;
  margin: 0;
  height: 50px;
  width: 200px;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  padding: 0px 0 2px;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  font-family: var(--font-header);
  text-align: left;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  background-color: transparent;
  white-space: nowrap;
  border: none;
  border-bottom: 2px solid #cfdef3;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.Tab-button:focus {
  outline: none;
}

.Tab-button:hover {
  background-color: #cfdef3;
}

.Tab-button:active {
  outline: none;
  background-color: #cfdef3;
}

.Tab-heading {
  font-size: 16px;
  font-family: var(--font-header);
}

.Place-location {
  padding: 0;
}

.Place-role {
  font-size: 14px;
}

.Place-location-text {
  font-size: 14px;
  color: var(--color-light-periwinkle);
  font-weight: 500;
}

.Place-location-link {
  padding: 0 3px;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
}

.Place-location-link:hover {
  box-shadow: inset 0 -55px 0 0 var(--color-light-periwinkle);
  color: white;
}

.Place-time {
  font-size: 12px;
  color: var(--color-dark-periwinkle);
}

.Icon-container {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 100px 0 50px 0;
}

.Icon-container li {
  display: inline-flex;
  padding-right: 40px;
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .Tab-button {
    width: 135px;
  }

  .Tab-container {
    padding-left: 50px;
  }
}
