.Footer {
  line-height: 1;
  font-size: 14px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: auto;
  min-height: 70px;
  padding: 15px;
}

a.Footer-credit:hover {
  color: var(--color-dark-blue);
}

a.Footer-credit {
  color: var(--color-dark-blue);
}

.Footer-credit {
  font-weight: 600;
  letter-spacing: 0.025em;
  font-family: Inconsolata, monospace;
  padding: 10px;
  text-decoration: none;
}
.Footer-credit:visited {
  color: rgb(168, 178, 209);
}
