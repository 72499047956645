.Dropdown-summary {
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  font-size: 16px;
  font-family: "Rowdies", cursive;

  display: flex;
  align-items: center;
}

.Dropdown {
  height: 150px;
  min-height: 200px;
  width: 100%;
  max-width: 300px;
}

.Dropdown p {
  font-size: 14px;
  line-height: 1.5;
}

.Dropdown a {
  color: inherit;
}

.Dropdown-content {
  margin-top: 1.2rem;
}

.Dropdown-point-icon {
  margin-left: 0.5em;
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .Dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
}
